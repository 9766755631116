import BaseService from "@/services/base-service";
import {
  RegisterCardRequest,
  RegisterCardResponse,
  NetsTerminalResponse,
} from "@/interfaces/api";

class PaymentService extends BaseService {
  /**
   * Registers a payment for the given invoice number
   *
   * @param {RegisterCardRequest} registerCardRequest - An object containing the invoice number and the customer email
   * @returns {Promise<RegisterCardResponse>>} A promise with a response containing either the url of the payment page or an error message
   */
  registerPayment(
    registerCardRequest: RegisterCardRequest
  ): Promise<RegisterCardResponse> {
    const endpointController = "Payment";
    const enpointRoute = "register";
    const registerPayment = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}`;
    return this.fetch<RegisterCardResponse>(registerPayment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerCardRequest),
    });
  }

  /**
   * Handles the response from the nets payment terminal by sending the results to the backend
   * which in turn will process the payment and return the result which will redirect to the
   * payment response page
   * @param {NetsTerminalResponse} terminalResponse - The response from the nets payment terminal
   * @returns {Promise<string>>} A promise with a response
   */
  handleNetsTerminalResponse(
    terminalResponse: NetsTerminalResponse
  ): Promise<string> {
    const endpointController = "Payment";
    const enpointRoute = "terminalResponse";
    const registerPayment = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}`;
    return this.fetch<string>(registerPayment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(terminalResponse),
    });
  }
}

export default new PaymentService();
